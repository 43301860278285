import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['close']

  connect() {
    if (this.hasCloseTarget) {
      this.closeTarget.addEventListener('keydown', this.closeKeyboardListener.bind(this));
      this.closeTarget.addEventListener('click', this.close.bind(this));
    }
  }

  disconnect() {
    if (this.hasCloseTarget) {
      this.closeTarget.removeEventListener('keydown', this.closeKeyboardListener.bind(this));
      this.closeTarget.removeEventListener('click', this.close.bind(this));
    }
  }

  close() {
    this.element.classList.add('opacity-0');
  }

  closeKeyboardListener(event) {
    const key = event.key || event.keyCode || event.which;
    const enterPressed = key == 'Enter' || key == 13

    if (enterPressed) this.close();
  }
}
