import { Controller } from "stimulus"
import Typewriter from 'typewriter-effect/dist/core';

export default class extends Controller {
  static values = {
    text: Array,
    string: String,
    loop: Boolean,
    cursor: String,
  }

  connect() {
    const loop = this.hasLoopValue ? this.loopValue : true
    loop ?  this.loopText() : this.typeString()
  }

  loopText() {
    this.typewriter = new Typewriter(this.element, {
      strings: this.textValue,
      autoStart: true,
      loop: true,
      cursor: this.cursorValue || ''
    });
  }

  typeString() {
    const cursor = this.cursorValue || '';
    this.typewriter = new Typewriter(this.element, {
      cursor: cursor
    });
    this.typewriter
      .typeString(this.stringValue)
      .pauseFor(4300)
      .callFunction(function(state) {
        state.elements.cursor.style.animation = 'none';
        this.typewriter.stop();
      }.bind(this))
      .start()
  }
}
