import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.wrapperClass = 'form-input';
    this.wrapperErrorClass = 'highlighted';
    this.inputClass = 'input';
    this.errorClass = 'error';
    this.errorNotificationClass = 'error-notification';
    this.errorNotificationPlaceholderClass = 'error-notification-placeholder';
  }

  connect() {
    this.errorNotification = this.element.querySelector(`.${this.errorNotificationClass}`);
    this.errorNotificationPlaceholder = document.querySelector(`.${this.errorNotificationPlaceholderClass}`);
    this.element
      .querySelectorAll(`.${this.wrapperClass}.${this.wrapperErrorClass} .${this.inputClass}`)
      .forEach(function(input) {
        input.setAttribute('data-error-value', input.value);
        if (this.radioOrCheckbox(input)) {
          input.addEventListener('change', this.toggleHighlight.bind(this));
        } else {
          input.addEventListener('input', this.toggleHighlight.bind(this));
        }
      }.bind(this));
  }

  radioOrCheckbox(input) {
    return (input.type == 'radio' || input.type == 'checkbox');
  }

  toggleHighlight(e) {
    const input = e.currentTarget;
    const wrapper = input.closest(`.${this.wrapperClass}`);
    const error = wrapper.querySelector(`.${this.errorClass}`);
    // Fairly safe to assume all radios and checkboxes are valid options and
    // errors on occur if no option is checked so we use null
    const errorValue =
      this.radioOrCheckbox(input) ? null : input.dataset.errorValue;

    if (this.currentValue(e) == errorValue) {
      wrapper.classList.add(this.wrapperErrorClass);
    } else {
      wrapper.classList.remove(this.wrapperErrorClass);
    }
    this.toggleErrorNotification();
  }

  toggleErrorNotification() {
    if (this.errorNotification == undefined) return;

    const inputWithError = this.element.querySelector(
      `.${this.wrapperClass}.${this.wrapperErrorClass} .${this.inputClass}`
    )

    if (inputWithError != undefined) {
      this.errorNotificationPlaceholder.classList.add('hidden');
      this.errorNotification.classList.remove('hidden');
    } else {
      this.errorNotificationPlaceholder.classList.remove('hidden');
      this.errorNotification.classList.add('hidden');
    }
  }

  currentValue(e) {
    const input = e.currentTarget;

    if (this.radioOrCheckbox(input)) {
      const siblings = input
        .closest(`.${this.wrapperClass}`)
        .querySelectorAll('input[type=checkbox], input[type=radio]')

      return Array.apply(null, siblings).find(option => option.checked).value;
    } else {
      return input.value;
    }
  }
}
