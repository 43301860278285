import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    document.body.classList.add('animated');
  }

  disconnect() {
    document.body.classList.remove('animated');
  }
}
